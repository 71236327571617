import _firebase		from './src/_firebase.js';
import aws  			from './src/aws.js';
import controller 		from './src/controller.js';
import dataset  		from './src/dataset.js';
import event  			from './src/event.js';
import helper  			from './src/helper.js';
import image  			from './src/image.js';
import meta 			from './src/meta.js';
import model  			from './src/model.js';
import ml				from './src/ml.js';
import nodeRed			from './src/nodeRed.js';
import openai  			from './src/openai.js';
import other 			from './src/other.js';
import project			from './src/project.js';
import table			from './src/table.js';
import ui  				from './src/ui.js';
import user  			from './src/user.js';
import vision			from './src/vision.js';

const indexObject = {
	_firebase,
	aws,
	controller,
	dataset,
	event,	
	helper,
	image,
	meta,
	model,
	ml,
	nodeRed,
	openai,
	other,
	project,
	table,
	ui,
	user,
	vision
};

export default indexObject;