'use strict';

import _firebase    from './_firebase.js';
import aws          from './aws.js';
import event        from './event.js';
import helper       from './helper.js';
import image        from './image.js';
import model        from './model.js';
import other        from './other.js';

import JSZip        from 'jszip';
import lzstring     from 'lz-string';
import saveAs       from 'file-saver';
import moment       from 'moment';

import {
    collection,
    doc,
    getDoc,
    getDocs,
    setDoc,
    updateDoc,
    deleteDoc,
    getCountFromServer,
    query,
    where,
    orderBy,
    limit,
    startAt,
    startAfter,
    limitToLast,
    endBefore,
    serverTimestamp,
    onSnapshot,
    Timestamp,
    writeBatch,
    or
} from 'firebase/firestore';

import { getFunctions, httpsCallable }  from 'firebase/functions';
import { ref, getDownloadURL }          from 'firebase/storage';

const dataset = {

    create: async function(opt) {
        const db = _firebase.firestore;
        let resp = { status: "error", error: false }

        opt.name = opt.name.replace(/\s/g, "_").replace(/[^\w\s]/gi, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").substring(0, 32).toLowerCase();

        let saveData = { name: opt.name, type: opt.type, createdAt: serverTimestamp(), description: "" }
        if (opt.description) saveData.description = opt.description;

        let type;
        switch (opt.type) { 
            case "MULTICLASS":              type = "class"; break;
            case "MULTILABEL":              type = "segm"; break;
            case "imageObjectDetection":    type = "obj"; break;
        }
        let docId = 'rp-' + type + '-' + opt.name;
        let docRef  = doc(db, 'dataset', docId);

        await setDoc(docRef, saveData)
            .then(async function() {
                resp.datasetId  = docId;
                resp.dataset    = opt.name.toString();
                resp.status     = "success";
            })
            .catch(function(error) { resp.error = error });

        if (resp.status == "success") {
            await event.saveEvent('dataset.create', { dataset: opt.name.toString(), qry: saveData }, false);

            if (opt.type == "MULTILABEL") {
                await this.createTag(docId, { tag: "0", name: "Anomaly", unclassified: true, anomaly: true, color: "#BC2B5F", description: "Used for Anomaly images" });
                await this.createTag(docId, { tag: "OK", name: "Normal", normal: true, color: "#2BBC33", description: "Used for Normal images" });
            } else {
                await this.createTag(docId, { tag: "0", name: "unclassified", unclassified: true });
            }
        }

        console.log("Status Creation dataset", resp);
        return resp;
    },

    list: async function(opt = {}) {
        let colRef		= collection(_firebase.firestore, 'dataset');
		let queryRef 	= query(colRef);

        if (opt.type) 		queryRef = query(queryRef, where('type', '==', opt.type));
        if (opt.trained)    queryRef = query(queryRef, where('trained', '==', true));
        if (opt.order)      queryRef = query(queryRef, orderBy('createdAt', opt.order));

		const snapshot = await getDocs(queryRef);
		let datasets = snapshot.docs.map(doc => {
			let item = doc.data();
			if (item.deleted || !item.type) return null;

			item.id             = doc.id;
            item.name           = item.name ? helper.capitalize(item.name.toUpperCase()) : null;
			item.createdDate    = item.createdAt ? helper.getTimestampDate(item.createdAt.toDate(), 'full') : null;
			item.updatedDate    = item.updatedAt ? helper.getTimestampDate(item.updatedAt.toDate(), 'full') : null;
			
			switch (item.type) {
				case "MULTICLASS": 				item.typeName = "Classification";   break;
				case "MULTILABEL": 				item.typeName = "Segmentation";     break;
				case "imageObjectDetection": 	item.typeName = "Object detection"; break;
				default: 						item.typeName = false;              break;
			}

			return item;
		}).filter(item => item != null);

        return datasets;
    },

    get: async function(datasetID, opt = {}) {
        let docRef      = doc(_firebase.firestore, 'dataset', datasetID);

        let snapshot    = await getDoc(docRef);
        let dataset     = snapshot.data();

        if (dataset) {
            dataset['id']           = snapshot.id;
            dataset['name']         = dataset.name ? helper.capitalize(dataset.name) : null;
            dataset['createdAt']    = dataset.createdAt ? helper.getTimestampDate(dataset.createdAt.toDate(), 'full') : null;

            switch (dataset.type) {
                case "MULTICLASS":              dataset['typeName'] = "Classification"; break;
                case "MULTILABEL":              dataset['typeName'] = "Segmentation"; break;
                case "imageObjectDetection":    dataset['typeName'] = "Object detection"; break;
                default:                        dataset['typeName'] = null; break;
            }
        }

		if (opt && dataset && Object.keys(dataset).length) {
            if (opt.models)             dataset['models']           = await this.getModels(dataset);
			if (opt.tagsCounter)        dataset['tagsCounter']      = await this.getTagStats(dataset); 
            if (opt.divisionsCounter)   dataset['dataDivision']     = await this.getDivisionStats(dataset);
		}

        return dataset;
    },

    hide: async function() {
        const colRef = collection(_firebase.firestore, 'dataset');
    
        try {
            const snapshot = await getDocs(colRef);
    
            snapshot.forEach(async (doc) => {
                const data = doc.data();
    
                if (!data.deleted) {
                    console.log("Hiding dataset:", doc.id);

                    await updateDoc(doc.ref, {
                        deleted: true,
                        hidden: true
                    });
                }
            });
        } catch (error) {
            console.error('Error al ocultar documentos:', error);
        }
    },
    
    unhide: async function() {
        const colRef = collection(_firebase.firestore, 'dataset');
    
        try {
            const snapshot = await getDocs(colRef);
    
            snapshot.forEach(async (doc) => {
                const data = doc.data();
    
                if (data.deleted === true && data.hided === true) {
                    console.log("Unhiding dataset:", doc.id);

                    await updateDoc(doc.ref, {
                        deleted: false,
                        hidden: false
                    });
                }
            });
        } catch (error) {
            console.error('Error al desocultar documentos:', error);
        }
    },

    update: async function(datasetID, data) {
        let resp = { status: "error", error: false };

        if (datasetID) {
            data["updatedAt"] = serverTimestamp();
            let docRef = doc(_firebase.firestore, 'dataset', datasetID);

            await setDoc(docRef, data, { merge: true })
                .then(async function() {
                    await event.saveEvent('dataset.update', { dataset: datasetID, data: data }, false);
                    resp.status = "success";
                })
                .catch(async () => { resp.error = "dataset not found" });
        }

        return resp;
    },

    delete: async function(datasetID) {
        await event.saveEvent('dataset.delete', { dataset: datasetID }, false);
        return await this.update(datasetID, { deleted: Boolean(true) });
    },

	getModels: async function(dataset) {
		let resp = { status: "error", error: false, count: 0, models: [] };

		const colRef = collection(_firebase.firestore, 'model');
		const docRef = doc(_firebase.firestore, 'dataset', dataset.id);

		let queryRef = query(colRef, where('dataset', '==', docRef), orderBy('createdAt', 'desc'));

		const snapshot = await getDocs(queryRef);
		snapshot.forEach(doc => {
			let model = doc.data();
			model.id = doc.id;

			if (model.createdAt) model.createdAt = helper.getTimestampDate(model.createdAt.toDate(), 'full');
			if (!model.deleted) resp.models.push(model);
		})

		resp.count = resp.models.length;

		resp.status	= resp.count > 0 ? "success" : "error";
    	resp.error 	= resp.count > 0 ? false : "no found models linked to this dataset";

		return resp;
	},

    createTag: async function(datasetId, opt) {
        const db = _firebase.firestore;
        let resp = { status: "error", error: false, dataset: datasetId };

        if (opt.tag && datasetId) {
            let tag = {
                name: opt.name ? opt.name : opt.tag,
                description: opt.description ? opt.description : "",
                imageCounter: Number(0),
                color: opt.color ? opt.color : helper.StringtoHex(opt.tag),
                unclassified: opt.unclassified ? Boolean(true) : Boolean(false),
                createdAt: serverTimestamp(),
            }

            if (opt.anomaly) tag.anomaly = Boolean(true);
            if (opt.normal) tag.normal = Boolean(true);

            let docRef = doc(db, 'dataset', datasetId.toString(), 'tag', opt.tag);
            await setDoc(docRef, tag)
                .then(async function() { resp.status = "success"; resp.message = "tag created"; await event.saveEvent('dataset.tag.create', { datasetId: datasetId, tag: tag }, false); })
                .catch(function(error) { resp.error = error });
        } else { resp.error = "new tag id and dataset id is required"; }

        return resp;
    },

    updateTag: async function(tag) {
		tag.data["updatedAt"] = serverTimestamp();
		let docRef = doc(_firebase.firestore, 'dataset', tag.dataset.toString(), 'tag', tag.id);
		await setDoc(docRef, tag.data, { merge: true });
		await event.saveEvent('dataset.tag.update', { dataset: tag.dataset.toString(), id: tag.id, data: tag.data }, false);
	},

    deleteTag: async function(tag) {
        const db = _firebase.firestore;
        let resp = { status: "success", error: false, message: "tag deleted" };
        let docRef = doc(db, 'dataset', tag.dataset.toString(), 'tag', tag.id);
        await deleteDoc(docRef);
        return resp;
    },

	getTag: async function(datasetID, tagId) {
        let tag = {};
        let docRef = doc(_firebase.firestore, 'dataset', datasetID.toString(), 'tag', tagId);
        let snapshot = await getDoc(docRef);

        if (snapshot.data()) {
            tag = snapshot.data();
            tag.id = snapshot.id;

            if (tag.createdAt)
                tag.createdAt = helper.getTimestampDate(tag.createdAt.toDate(), 'full');
        }

        return tag;
    },

	getTags: async function(datasetID, unclassified = true) {
        let tags = {};

        if (datasetID) {
            let colRef = collection(_firebase.firestore, 'dataset', datasetID.toString(), 'tag');
            let querySnapshot = await getDocs(query(colRef, orderBy('name', 'asc')));

            const promises = querySnapshot.docs.map(async (snapDoc) => {
                let item = snapDoc.data();
                item.id = snapDoc.id;

                /* if (item.id === "0") {
                    item.name = item.name ? item.name : "Unclassified";
                    const labeledDocRef	= doc(_firebase.firestore, `${snapDoc.ref.path}/labeled/counter`);
                    const labeledDoc = await getDoc(labeledDocRef);
                    if (labeledDoc.exists) item.labeled = labeledDoc.data();
                } */

                item.name   = item.name ? helper.capitalize(item.name) : item.name;
                item.color  = item.color || helper.StringtoHex(snapDoc.id);

                if (!item.color) this.updateTag({ id: snapDoc.id, dataset: datasetID.toString(), data: { color: item.color } });
                if (unclassified || item.id !== "0") tags[item.id] = item;
            });

            await Promise.all(promises);
        }

        return tags;
    },

	getTagStats: async function(dataset, tagMap) {
		const db 		= _firebase.firestore;
		const colRef	= collection(db, 'image');
		const docRef    = doc(db, 'dataset', dataset.id); 

		const tagStats 	= {};

		if (dataset.type === 'MULTICLASS') {
			tagStats.tags = await this.getTagsQueries(dataset, colRef, docRef, tagMap); 
			tagStats.labeled = Object.values(tagStats.tags).reduce((total, valor) => total + valor, 0);
		}
        
        else if (dataset.type === 'MULTILABEL' || dataset.type === 'imageObjectDetection') {
			const result = await this.getTagsQueries(dataset, colRef, docRef, tagMap);
            
			tagStats.tagsLabeled    = result.tagsLabeled;
			tagStats.tagsLabeledImg = result.tagsLabeledImg;

            if (dataset.type === 'MULTILABEL') 
                tagStats.anomaly = result.anomalyValue;
            else 
                tagStats.labeled = result.ids.length;
		}

		await this.parseTagsCounterObj(dataset, tagStats);
		
        const total = await getCountFromServer(query(colRef, where('dataset', '==', docRef)));
		tagStats.count = total.data().count;

		if (dataset.type === 'MULTILABEL')
            tagStats.normal = total.data().count - tagStats.anomaly;
        else
            tagStats.notLabeled = total.data().count - tagStats.labeled;

		return tagStats;
	},

    getTagsQueries: async function(dataset, colRef, docRef, tagMap = false) {
		const db = _firebase.firestore;
		const snapDocs  = await getDocs(collection(db, 'dataset', dataset.id, 'tag'));

        let tagMapTags = [];
        if (tagMap) {
            tagMapTags = Object.values(tagMap.tags).flatMap(tagObj => Object.keys(tagObj.tags).filter(tag => tag !== 'count'));
        }

		if (dataset.type === 'MULTICLASS') {
			const tags = {};
			
			const queries = snapDocs.docs.map(async (tagDoc) => {
                if (!tagMapTags.length || tagMapTags.includes(tagDoc.id)) {
                    const tagQueryRef = query(colRef, where("dataset", "==", docRef), where("tag", "==", tagDoc.ref));
	
                    const nTags = await getCountFromServer(tagQueryRef);
                    tags[tagDoc.id] = nTags.data().count;
                }
			});
			
			await Promise.all(queries);
			return tags;
		}

        else if (dataset.type === 'MULTILABEL') {
			const ids = [], tagsLabeled = {}, tagsLabeledImg = {};

            const anomalyRef    = doc(db, 'dataset', dataset.id, 'tag', '0');
            const anomalyCount  = await getCountFromServer(query(colRef, where("dataset", "==", docRef), where("tag", "==", anomalyRef)));
            const anomalyValue  = anomalyCount.data().count;
           
			const queries = snapDocs.docs.map(async (tagDoc) => {
				const tagQueryRef = query(colRef, where("dataset", "==", docRef), where("tagsContained", "array-contains", tagDoc.ref));
				
				const snapshot = await getDocs(tagQueryRef);
				const labeledCount = snapshot.size > 0 ? snapshot.docs.reduce((count, doc) => { 
					if (!ids.includes(doc.id)) 
						ids.push(doc.id);
					return count + doc.data().tagsContained.filter(tag => tag.id === tagDoc.id).length;
				}, 0) : 0;

				tagsLabeled[tagDoc.id] = labeledCount;
				tagsLabeledImg[tagDoc.id] = snapshot.size;
			});

			await Promise.all(queries);
			return { ids, tagsLabeled, tagsLabeledImg, anomalyValue };
		}
        
        else if (dataset.type === 'imageObjectDetection') {
            const ids               = new Set();
            const tagsLabeled       = {};
            const tagsLabeledImg    = {};

            const queries = snapDocs.docs.filter(tagDoc => !tagMapTags.length || tagMapTags.includes(tagDoc.id)).map(async (tagDoc) => {
                const tagQueryRef = query(colRef, where("dataset", "==", docRef), where("tagsContained", "array-contains", tagDoc.ref));
                const snapshot = await getDocs(tagQueryRef);
                const labeledCount = snapshot.docs.reduce((count, doc) => {
                    ids.add(doc.id);
                    return count + doc.data().tagsContained.filter(tag => tag.id === tagDoc.id).length;
                }, 0);

                tagsLabeled[tagDoc.id]      = labeledCount;
                tagsLabeledImg[tagDoc.id]   = snapshot.size;
            });

            await Promise.all(queries);

            delete tagsLabeled['0'];
            delete tagsLabeledImg['0'];

            return { ids: Array.from(ids), tagsLabeled, tagsLabeledImg };
        }
	},

    parseTagsCounterObj: async function(dataset, tagsCounter) {
		const tags = await this.getTags(dataset.id);
        dataset['tags'] = tags;
        
        if (dataset.type === 'imageObjectDetection') 
            delete(dataset['tags']['0']);

		tagsCounter.names	= {};
		tagsCounter.colors 	= {};

		for (const tag of Object.keys(tags)) {
			tagsCounter.names[tag]	= tags[tag].name;
			tagsCounter.colors[tag]	= (tags[tag] && tags[tag].color ? tags[tag].color : helper.StringtoHex(tag));
		}

		tagsCounter.chart = { labels: ["Labeled"], datasets: [{ data: [tagsCounter.labeled], backgroundColor: [helper.StringtoHex("LabeledGreen")], hoverBackgroundColor: [helper.StringtoHex("LabeledGreen")], borderWidth: 5, borderColor: "#fff"}]}

		if (tagsCounter.notLabeled) {
			tagsCounter.chart.labels.push("Unclassified");
			tagsCounter.chart.datasets[0].data.push(tagsCounter.notLabeled);
			tagsCounter.chart.datasets[0].backgroundColor.push(helper.StringtoHex("UnclassifiedRed"));
			tagsCounter.chart.datasets[0].hoverBackgroundColor.push(helper.StringtoHex("UnclassifiedRed"));
		}

		return tagsCounter;
	},
	
    getDivisionStats: async function(dataset, tagsType = false, byTag = false, tagMap = false) {
        const divisions = {
            total:          0,
            train:          0,
            test:           0,
            validation:     0,
            predetermined:  0,
        }

        let total = 0;
        let queryRef;
        let queryPromises;
        
        const datasetType = dataset.type;

        const colRef = collection(_firebase.firestore, 'image');
        const docRef = doc(_firebase.firestore, 'dataset', dataset.id);

        let imgsId = [];

        if (tagMap) {
            for (let key in tagMap.tags) {
                const tags = tagMap.tags[key].tags;
                let tagImgsId = [];
                for (let tag in tags) {
                    if (tag !== 'count') {
                        const tagRef = doc(_firebase.firestore, 'dataset', dataset.id, 'tag', tag);
                        queryPromises = Object.keys(divisions).map(async (division) => {
                            if (tag === 'OK')
                                queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tag', '==', tagRef));
                            else 
                                queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tagsContained', 'array-contains', tagRef));
                            const querySnapshot = await getDocs(queryRef);
                            querySnapshot.forEach((doc) => {
                                if (!imgsId.includes(doc.id)) {
                                    divisions[division]++;
                                    imgsId.push(doc.id);
                                }
                                if (!tagImgsId.includes(doc.id)) {
                                    tagImgsId.push(doc.id);
                                }
                            });
                            total = imgsId.length;
                            tagMap.tags[key].total = tagImgsId.length;
                        });
                    }
                }
            }
        } else if (!tagsType && !byTag) {
            queryPromises = Object.keys(divisions).map(async (division) => {
                queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()));
                return getCountFromServer(queryRef).then((count) => { 
                    divisions[division] = count.data().count;
                    total += count.data().count;
                })
            });
        } else if (tagsType && !byTag) {
            if (tagsType == 'labeled' || tagsType == 'anomaly') {
                queryPromises = Object.keys(divisions).map(async (division) => {
                    queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tags', '!=', []));
                    return getCountFromServer(queryRef).then((count) => { 
                        divisions[division] = count.data().count;
                        total += count.data().count;
                    })
                });
            } else if (tagsType == 'notLabeled' || tagsType == 'normal') { 
                queryPromises = Object.keys(divisions).map(async (division) => {
                    queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tags', '==', []));
                    return getCountFromServer(queryRef).then((count) => { 
                        divisions[division] = count.data().count;
                        total += count.data().count;
                    })
                });
            }
        } else if (byTag) {
            const tagRef = doc(_firebase.firestore, 'dataset', dataset.id, 'tag', byTag);
            if (datasetType === 'imageObjectDetection') {
                queryPromises = Object.keys(divisions).map(async (division) => {
                    queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tagsContained', 'array-contains', tagRef));
                    return getCountFromServer(queryRef).then((count) => { 
                        divisions[division] = count.data().count;
                        total += count.data().count;
                    })
                });
            } else if (datasetType === 'MULTILABEL') {
                if (byTag === '0' || byTag === 'OK') { 
                    queryPromises = Object.keys(divisions).map(async (division) => {
                        queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tag', '==', tagRef));
                        return getCountFromServer(queryRef).then((count) => { 
                            divisions[division] = count.data().count;
                            total += count.data().count;
                        })
                    });
                } else {
                    queryPromises = Object.keys(divisions).map(async (division) => {
                        queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tagsContained', 'array-contains', tagRef));
                        return getCountFromServer(queryRef).then((count) => { 
                            divisions[division] = count.data().count;
                            total += count.data().count;
                        })
                    });
                }
            } else {
                queryPromises = Object.keys(divisions).map(async (division) => {
                    queryRef = query(colRef, where('dataset', '==', docRef), where('set', '==', division.toUpperCase()), where('tag', '==', tagRef));
                    return getCountFromServer(queryRef).then((count) => { 
                        divisions[division] = count.data().count;
                        total += count.data().count;
                    })
                });
            }
        }

        try {
            await Promise.all(queryPromises);
            divisions['total'] = total;
        } catch (error) {
            console.log("Error:", error);
        }

        return divisions;
    },

	getStatus: async function(dataset) {
        let lastImportEvent     = dataset.automl ? await event.get({ type: "dataset.import", dataset: dataset.automl, last: true }) : {}
        let lastTrainingEvent   = dataset.automl ? await event.get({ type: "dataset.training", dataset: dataset.automl, last: true }) : {}
        let lastImport		    = { inProgress: false }
        let lastTraining 	    = { inProgress: false }
        let lastUploadZip 	    = { inProgress: false }

        Object.assign(lastUploadZip, {
            uploadRef:          dataset.uploadRef,
            uploadStatus:       dataset.uploadStatus,
            uploadStatusMsg:    dataset.uploadStatusMsg,
            inProgress:         dataset.uploadStatus === "processing"
        });

        if (Object.keys(lastImportEvent).length) {
            if (lastImportEvent.name)               lastImport.name         = lastImportEvent.name;
            if (lastImportEvent.payload.dataset)    lastImport.datasetId    = lastImportEvent.payload.dataset;
            if (lastImportEvent.createdAt)          lastImport.created      = helper.getTimestampDate(lastImportEvent.createdAt.toDate(), 'full');
            if (lastImportEvent.payload.uid)        lastImport.uid          = lastImportEvent.payload.uid;

            if (lastImportEvent.payload && lastImportEvent.payload.operation) {
                let operationName   = lastImportEvent.payload.operationName ? lastImportEvent.payload.operationName : lastImportEvent.payload.operation;
                let lastImportOper  = await other.httpsCallable('api/model/operation/status/' + operationName.replace(/\//g, "--"));

                if (lastImportOper.data) lastImport.operation = { name: lastImportOper.data.name, result: lastImportOper.data.result, done: lastImportOper.data.done };
                if (lastImport.operation && !lastImport.operation.done) lastImport.inProgress = true;
            }
        }

        if (Object.keys(lastTrainingEvent).length) {
            if (lastTrainingEvent.name)                 lastTraining.name       = lastTrainingEvent.name;
            if (lastTrainingEvent.payload.dataset)      lastTraining.datasetId  = lastTrainingEvent.payload.dataset;
            if (lastTrainingEvent.payload.displayname)  lastTraining.model      = lastTrainingEvent.payload.displayname;
            if (lastTrainingEvent.createdAt)            lastTraining.created    = helper.getTimestampDate(lastTrainingEvent.createdAt.toDate(), 'full');
            if (lastTrainingEvent.payload.uid)          lastTraining.uid        = lastTrainingEvent.payload.uid;

            if (lastTrainingEvent.payload && lastTrainingEvent.payload.operationID) {
                let pipName = lastTrainingEvent.payload.operationID.name ? lastTrainingEvent.payload.operationID.name : lastTrainingEvent.payload.operationID;
                let lastTrainingPip = await other.httpsCallable('api/model/trainingpipeline/status/' + pipName.replace(/\//g, "--"));

                if (lastTrainingPip.data) {
                    lastTraining.pipeline = {
                        name:           lastTrainingPip.data.name,
                        displayName:    lastTrainingPip.data.displayName,
                        startTime:      helper.getFbDate(lastTrainingPip.data.startTime),
                        trainBudget:    lastTrainingEvent.payload.trainBudget,
                        state:          lastTrainingPip.data.state,
                        error:          lastTrainingPip.data.error,
                        modelToUpload:  lastTrainingPip.data.modelToUpload ? lastTrainingPip.data.modelToUpload.name : false,
                        done:           lastTrainingEvent.status && lastTrainingEvent.status == "done" ? true : false
                    }

                    if (lastTrainingPip.data.state == "PIPELINE_STATE_SUCCEEDED" || lastTrainingPip.data.state == "PIPELINE_STATE_FAILED") lastTraining.pipeline.done = true;

                    if (lastTraining.model && lastTraining.pipeline.modelToUpload) {
                        let trainedModel = await model.get(lastTraining.model);
                        if (!trainedModel.automl) model.update(lastTraining.model, { automl: lastTraining.pipeline.modelToUpload.toString().split('/').pop() });
                    }
                } else { lastTraining.pipeline = { name: lastTrainingEvent.payload.operationID, error: "not found", trainBudget: lastTrainingEvent.payload.trainBudget, state: "PIPELINE_STATE_FAILED", done: true } }
                
                if (lastTraining.pipeline && !lastTraining.pipeline.done) lastTraining.inProgress = true
            }
            if (!dataset.trained) await this.update(dataset.id, { trained: Boolean(true) })
        }

        let resp = {
            dataset:    dataset.id,
            trained:    Object.keys(lastTrainingEvent).length ? true : false,
            inProgress: lastImport.inProgress || lastTraining.inProgress || lastUploadZip.inProgress ? true : false,
            action:     lastImport.inProgress ? 'importing' : lastTraining.inProgress ? 'training' : lastUploadZip.inProgress ? 'Uploading' : false,
            import:     lastImport,
            training:   lastTraining,
            uploadZip:  lastUploadZip,
        };

        //AWS Models
        let datasetModels = dataset.models;
        if (datasetModels.models.length) {
            for (let index in datasetModels.models) {
                if (datasetModels.models[index].aws) {
                    let model = await aws.getModel(datasetModels.models[index].aws);
                    if (model.response && model.response.ModelDescription && model.response.ModelDescription.Status && model.response.ModelDescription.Status == "TRAINING") {
                        resp.inProgress             = true;
                        resp.action                 = 'training';
                        resp.training               = model.response.ModelDescription;
                        resp.training.modelName     = datasetModels.models[index].id;
                        resp.training.projectName   = datasetModels.models[index].aws;
                    }
                }
            }
        }

        return resp;
    },

	getImages: async function(dataset, opt = false) {
        const db = _firebase.firestore;
        
        let imgsQuery = collection(db, 'image');

        let media 	    = { media: [] };
        let pagination  = { currentPage: 0, perPage: opt.perPage ? opt.perPage : 12, pages: 1, total: 0, init: null, first: null, last: null, prev: false, next: true, toend: false };
        
        if (opt.perPage)        opt.pagination = true;
        if (opt.paginationQry)  pagination = opt.paginationQry;

        if (dataset.id) {
            const datasetId     = dataset.id.toString();
            const datasetType   = dataset.type ? dataset.type : false;
            const dataDivision  = dataset.dataDivision ? dataset.dataDivision : false;

            const objtagsType   = opt.objtagsType   ? opt.objtagsType.toString()    : false;
            const objByTag      = opt.objByTag      ? opt.objByTag.toString()       : false;
            const objDivision   = opt.objDivision   ? opt.objDivision.toString()    : false;

            let tagRef = doc(db, "dataset", datasetId);
            media.type = datasetType;
            
            if (datasetType) {
                /* Filtrar por objTagsType */
                if (datasetType == 'MULTILABEL' || datasetType == 'imageObjectDetection') {
                    imgsQuery = query(imgsQuery, where('dataset', '==', tagRef));

                    if (objtagsType && objtagsType == 'notLabeled') {
                        imgsQuery = query(imgsQuery, where('tags', '==', []));

                    } else if (objtagsType &&  objtagsType == 'labeled') {
                        imgsQuery = query(imgsQuery, where('tags', '!=', []));

                    } else if (objtagsType &&  objtagsType == 'normal') {
                        let normalRef = doc(db, "dataset", datasetId, "tag", 'OK');
                        imgsQuery = query(imgsQuery, where('tag', '==', normalRef));

                    } else if (objtagsType &&  objtagsType == 'anomaly') {
                        let anomalyRef = doc(db, "dataset", datasetId, "tag", '0');
                        imgsQuery = query(imgsQuery, where('tag', '==', anomalyRef));
                    }
                } else { imgsQuery = query(imgsQuery, where('dataset', '==', tagRef)); }

                /* Filtrar por objByTag */
                if (objByTag && objByTag != 'all') {
                    if (datasetType == 'MULTICLASS') {
                        tagRef = doc(db, "dataset", datasetId, "tag", objByTag);
                        imgsQuery = query(imgsQuery, where('tag', '==', tagRef));
                    } else if (datasetType == 'MULTILABEL' || datasetType == 'imageObjectDetection') {
                        tagRef = doc(db, "dataset", datasetId, "tag", objByTag);
                        imgsQuery = query(imgsQuery, where('tagsContained', 'array-contains', tagRef));
                    }
                }

                /* Filtrar usando tagMap */
                if (opt.tagMap) {
                    let tagRefs = [];
                    for (let key in opt.tagMap.tags) {
                        const tags = opt.tagMap.tags[key].tags;
                        for (let tag in tags) if (tag !== 'count') tagRefs.push(doc(db, "dataset", datasetId, "tag", tag));
                    }
                    if (datasetType == 'MULTICLASS') {
                        imgsQuery = query(imgsQuery, where('tag', 'in', tagRefs));
                    } else if (datasetType == 'MULTILABEL' || datasetType == 'imageObjectDetection') {
                        imgsQuery = query(imgsQuery, or(where('tagsContained', 'array-contains-any', tagRefs), where('tag', 'in', tagRefs)));
                    }
                }

                /* Filtrar por objDivision */
                if (objDivision && objDivision != 'all') {
                    imgsQuery = query(imgsQuery, where('set', '==', objDivision.toUpperCase()));
                }
            }

            imgsQuery = query(imgsQuery, orderBy(opt.order ? opt.order : "date", opt.direction ? opt.direction : "desc"), limit(10000));
            const imagesCopy = imgsQuery;
            
            if (opt.pagination) {
                if (opt.action && opt.action == "init" && pagination.init) {
                    imgsQuery = query(imgsQuery, startAt(pagination.init));
                } else if (opt.action && opt.action == "next" && pagination.last) {
                    imgsQuery = query(imgsQuery, startAfter(pagination.last));
                }

                if (opt.action && opt.action == "prev" && pagination.first) {
                    imgsQuery = query(imgsQuery, endBefore(pagination.first));
                    if (pagination.perPage) imgsQuery = query(imgsQuery, limitToLast(pagination.perPage));
                } else if (opt.action && opt.action == "end" && pagination.end) {
                    imgsQuery = query(imgsQuery, startAt(pagination.end));
                    if (pagination.perPage) imgsQuery = query(imgsQuery, limitToLast(pagination.perPage));
                } else if (opt.pagination && pagination.perPage) {
                    if (!opt.action && pagination.first) imgsQuery = query(imgsQuery, startAt(pagination.first), limit(pagination.perPage));
                    else imgsQuery = query(imgsQuery, limit(pagination.perPage));
                }
            } else if (opt.limit) { imgsQuery = query(imgsQuery, limit(opt.limit)); }
           
            let imgs = await getDocs(imgsQuery);
            let promises = imgs.docs.map(async (doc) => {
                let img         = doc.data();
                img.id          = doc.id;
                img.tag         = img.tag.path;
                img.tagName     = img.tag.toString().split('/');
                img.fileName    = img.name.toString().split('/');

                if (img.date)         img.createdDate = helper.getTimestampDate(img.date, 'full');
                if (img.updatedAt)    img.updatedDate = helper.getTimestampDate(img.updatedAt.toDate(), 'full');
                else img.updatedDate  = img.createdDate;

                if (img.imageData && img.imageData._byteString && img.imageData._byteString.binaryString) {
					img.img_base64_val = "data:image/jpeg;base64," + btoa(img.imageData._byteString.binaryString);;
				} else if (img.imageData && img.imageData.previewImg) {
                    img.img_base64_val = img.imageData.previewImg;
                }

                media.media.push(img);
            });

            await Promise.all(promises);

            media.count = media.media.length;

            if (opt.pagination) {
                pagination.total = (dataDivision && opt.objDivision && opt.objDivision != 'all') ? dataDivision[opt.objDivision] : dataDivision['total'];

                if (imgs.docs && imgs.docs[0]) {
                    if (!pagination.init) pagination.init = imgs.docs[0];
                    pagination.first = imgs.docs[0];
                    pagination.last = imgs.docs[imgs.docs.length - 1];
                }

                switch (opt.action) {
                    case 'init':
                        pagination.currentPage = 0;
                        break;
                    case 'prev':
                        pagination.prev = pagination.next = false;
                        pagination.currentPage--;
                        break;
                    case 'next':
                        pagination.prev = pagination.next = false;
                        pagination.currentPage++;
                        break;
                    case 'end':
                        pagination.currentPage = pagination.pages - 1;
                        break;
                    default:
                        break;
                }

                pagination.pages = Math.ceil(pagination.total / pagination.perPage);
                if (pagination.pages == 0) pagination.pages = 1;

                if (!pagination.end) {
                    let skip = (pagination.pages - 1) * pagination.perPage;
                    if (skip > 0) {
                        const count = await getCountFromServer(imagesCopy);                        
                        const skipCount = count.data().count - skip;
                        const skipSnap = await getDocs(query(imagesCopy, limitToLast(skipCount)));
                        pagination.end = skipSnap.docs[0];
                    }
                }

                if ((pagination.currentPage + 1) == pagination.pages) pagination.next = false;
                else pagination.next = true;

                if (pagination.currentPage == 0) pagination.prev = false;
                else pagination.prev = true;

                if (media.count) media.pagination = pagination;
            }

            if (opt.resume) {
                let resume = {
                    count: media.media.length,
                    images: media.media.map(item => ({ name: item.name, uri: item.uri, set: item.set }))
                };
                return resume;
            }
        }

        return media;
    },

    getImagesData: async function(opt, onProgressUpdate) {
        let imagesToProcess = [];
        
        if (!opt.completeDataset) imagesToProcess = opt.images.filter(img => opt.selectedIds.has(img.id));
        else imagesToProcess = opt.images;

        if (!imagesToProcess.length) return;

        const chunkSize = 20;
        let images = [];

        for (let i = 0; i < imagesToProcess.length; i += chunkSize) {
            const chunkFiles = imagesToProcess.slice(i, i + chunkSize);

            const imagePromises = chunkFiles.map(async img => {
                let filename = img.name.substr(img.name.lastIndexOf("/") + 1);
                return await image.getStorageUrl(img.uri)
                    .then(imageUrl => 
                        fetch(imageUrl.url)
                        .then(response => response.blob())
                        .then(imageBlob => ({ imageBlob, imageUrl }))
                    )
                    .then(({ imageBlob, imageUrl }) => {
                        opt.zipCounter++;
                        onProgressUpdate(opt.zipCounter);

                        let data = false;
                        let type = null;

                        if (img.masks && img.masks.objects) {
                            data = img.masks;
                            type = 'masks';
                        } else if (img.mask) {
                            data = img.mask;
                            type = 'mask';
                        } else if (img.tags && img.tags.length) {
                            data = img.tags;
                            type = 'box';
                        }

                        return {
                            name:           filename,
                            tag:            img.tag,
                            set:            img.set,
                            file:           imageBlob,
                            data:           data,
                            type:           type,
                            imageUrl:       imageUrl.url,
                            createdDate:    img.createdDate,
                        };
                    });
            });

            try {
                const chunkResults = await Promise.all(imagePromises);
                images.push(...chunkResults);
            } catch (error) {
                console.error("An error occurred:", error);
            }
        }

        return images;
    },    

	getPreview: async function(datasetID) {
        let resp = { status: "error", error: false, preview: false };

        if (datasetID) {
            let colRef  = collection(_firebase.firestore, 'image');
            let docRef  = doc(_firebase.firestore, 'dataset', datasetID.toString());
            let images  = query(colRef, where('dataset', '==', docRef), orderBy('date', 'desc'), limit(1));
            let snap    = await getDocs(images);

			if (!snap.empty) {
				let doc = snap.docs[0];
				let p = doc.data();
				p.id = doc.id;

				if (p.imageData && p.imageData._byteString && p.imageData._byteString.binaryString) {
					resp.preview = "data:image/jpeg;base64," + btoa(p.imageData._byteString.binaryString);
					resp.status = "success";
				} else if (p.imageData && p.imageData.previewImg) {
                    resp.preview = p.imageData.previewImg;
					resp.status = "success";
                }
			} else { resp.error = "The dataset does not contain images"; }
        } else { resp.error = "dataset Id is required"; }

        return resp;
    },

    generateResume: async function(datasetID, opt = {}) {
        let resp = { status: "error", error: false, resume: "" };

        if (datasetID) {
            let dataset = await this.get(datasetID);
            if (opt.tagsCounter) { dataset.tagsCounter = opt.tagsCounter; } 
			else { dataset.tagsCounter = await this.getTagStats(dataset); }

            let typeResumes = {
                'MULTICLASS': 'This dataset is based on the Image classification with a single label.',
                'MULTILABEL': 'This dataset is based on the Image classification with multiple labels.',
                'imageObjectDetection': 'This data set is based on the classification of image by object detection.'
            };
        
            resp.resume = typeResumes[dataset.type] || "";

            if (dataset.tagsCounter.tags && Object.keys(dataset.tagsCounter.tags).length > 1) {
                resp.resume += ' Contains ' + (Object.keys(dataset.tagsCounter.tags).length - 1) + ' classification tags and ' + dataset.tagsCounter.count + ' images, of which ' + dataset.tagsCounter.labeled + ' are labeled.';
            }
            if (dataset.tagsCounter.tagsLabeled && Object.keys(dataset.tagsCounter.tagsLabeled).length) {
                resp.resume += ' Contains ' + (Object.keys(dataset.tagsCounter.tagsLabeled).length) + ' classification tags and ' + dataset.tagsCounter.count + ' images, of which ' + dataset.tagsCounter.labeled + ' are labeled.';
                resp.resume += ' and ' + dataset.tagsCounter.notLabeled + ' images are unclassified. Remember that unclassified images will not be used for training.';
            }
            if (dataset.trained && opt.models && opt.models.count) resp.resume += ' The set is trained and ' + opt.models.count + 'prediction models have been generated.';

            resp.status = "success";
        } else { resp.error = "dataset Id is required"; }
		
        return resp;
    },

    createOperation: async function(datasetID, opt = false, usapi) {
        let resp = { status: "error", error: false };
        const operation = opt.type === 'move' ? 'moveOperation' : 'copyOperation';
        const options = { method: "POST", body: JSON.stringify({ images: opt.images }) };
        const functionsUseApi = getFunctions(_firebase.firebase, 'http://127.0.0.1:5001/rosepetal-dev/us-central1/usapi');
        const action = httpsCallable(functionsUseApi, 'rosepetal-dev/us-central1/usapi/dataset/' + datasetID + '/' + operation);
        await action(options, { body: JSON.stringify(options) }).then((result) => { resp.data = result.data; resp.status = "success"; }).catch(async (error) => { resp.error = error });
        return resp;
    },

    deleteImagesBatch: async function(datasetID, images) {
        let resp = { status: "error", error: false };
        await other.httpsCallable('api/dataset/' + datasetID + '/deleteBulk/' + encodeURIComponent(images));
        resp.status = "success";
        return resp;
    },

    manageImages: async function(datasetID) {
        const shouldDelete  = false; //Seleccionar entre borrar o actualizar

        const datasetRef    = doc(_firebase.firestore, 'dataset', datasetID);
        const imagesRef     = collection(_firebase.firestore, 'image');
        const query         = query(imagesRef, where('dataset', '==', datasetRef));
        const querySnapshot = await getDocs(query);
        
        const batch = writeBatch(_firebase.firestore);
        
        querySnapshot.forEach((doc) => {
            if (shouldDelete) {
                batch.delete(doc.ref);
            } else {
                const newName = `${datasetID}/${doc.data().name}.jpg`;
                batch.update(doc.ref, {name: newName});
            }
        });
        
        await batch.commit();
        if (shouldDelete) {
            console.log(`Todas las imágenes del dataset ${datasetID} han sido eliminadas.`);
        } else {
            console.log(`Todos los nombres de las imágenes del dataset ${datasetID} han sido actualizados.`);
        }
    },

    updateAutomlId: async function(datasetID) {
        const db = _firebase.firestore;

        let dsRef = doc(db, 'dataset', datasetID);
        let queryRef = query(collection(db, 'model'), where('dataset', '==', dsRef), orderBy('createdAt', 'desc'));

        await getDocs(queryRef).then(async snapshot => {
            snapshot.forEach(async doc => {
                let item = doc.data();
                item.id = doc.id;

                if (!item.deleted && item.trainOperation && item.trainOperation.operationName && !item.automl) {
                    let trainingPipelineOperation = await other.httpsCallable('api/model/trainingpipeline/status/' + item.trainOperation.operationName.replace(/\//g, "--"));

                    console.log('Model training: ' + item.id, trainingPipelineOperation.data);

                    if (trainingPipelineOperation.data) {
                        if (trainingPipelineOperation.data.state == "PIPELINE_STATE_SUCCEEDED" || trainingPipelineOperation.data.state == "PIPELINE_STATE_FAILED") {
                            model.update(item.id, { automl: trainingPipelineOperation.data.modelToUpload.name.toString().split('/').pop() });
                        }
                    }
                }
            });
        })
    },

    checkImagesSize: async function(datasetId) {
        const storage = _firebase.storage;
        let resp = { status: "error", error: false };

        if (datasetId) {
            let media = await this.getImages({ datasetID: datasetId });
            if (media.count) {
                for (var i = 0; i < Object.keys(media.media).length; i++) {
                    if (media.media[i].uri) {
                        let urlRef = ref(storage, media.media[i].uri);
                        let imgRef = await getDownloadURL(urlRef);
                        let img = new Image();
                        img.onload = function() { console.log(`Image ${media.media[i].id} width: ${this.width}, height: ${this.height}`); };
                        img.src = imgRef;
                    }
                }
                resp.status = "success";
            } else { resp.error = "dataset does not have images"; }
        } else { resp.error = "dataset Id is required"; }

        return resp;
    },

    refreshCounters: async function(datasetID, usapi) {
        let resp = { status: "error", error: false };
        const functionsUsApi = getFunctions(_firebase.firebase, usapi);
        const action = functionsUsApi.httpsCallable('dataset/' + datasetID + '/refreshCounters');
        await action({}).then(() => { resp.status = "success"; }).catch(async (error) => { resp.error = error });
        return resp;
    },

    getMaskCounter: async function(datasetID, Tag = false) {
        const db = _firebase.firestore;
        let countMaskImages = 0;

        let colRef = collection(db, 'image');
        let datasetRef = doc(db, 'dataset', datasetID.toString());
        let tagRef = doc(db, 'dataset', datasetID.toString(), 'tag', Tag.toString());
        let queryRef = query(colRef, where('dataset', '==', datasetRef), where('tag', '==', tagRef));

        let snapshot = await getDocs(queryRef);
        snapshot.forEach(async (doc) => {
            let p = doc.data();
            if (p.mask && p.mask.imageJson)
                countMaskImages++;
        });

        return countMaskImages;
    },

    getDatasetAnnotationSetList: async function(datasetID) {
        let resp = { status: "error", error: false, last: false };

        if (datasetID) {
            resp.apiQry = 'api/model/annotationset/dataset_id/' + datasetID;
            let annotationsgResp = await other.httpsCallable(resp.apiQry);

            if (annotationsgResp.data) {
                resp.annotationSet = annotationsgResp.data;
                if (annotationsgResp.data[0] && annotationsgResp.data[0].name) {
                    resp.annotationSet.sort(function(a, b) { return b.createTime.seconds - a.createTime.seconds; })
                    resp.last = resp.annotationSet[0].name.toString().split('/').pop();
                }
                resp.status = "success";
            } else { resp.error = "failed to get annotation set list"; }
        } else { resp.error = "Automl dataset Id is required"; }

        return resp;
    },    

    updateTagsContained: async function(datasetID) {
        let db          = _firebase.firestore;
        let imgsCol     = collection(db, 'image');
        let datasetRef  = doc(db, "dataset", datasetID);

        const snapshot = await getDocs(query(imgsCol, where('dataset', '==', datasetRef)));

        if (snapshot.empty) {
            console.log("No matching documents.");
            return;
        }

        snapshot.forEach(async (document) => {
            const data = document.data();
            if (!data.tagsContained) {
                const tagsContained = data.tags.map(item => item.tag);
                const docRef = doc(db, 'image', document.id);
                await updateDoc(docRef, {
                    tagsContained: tagsContained
                }).then(() => {
                    console.log(`Document ${document.id} updated successfully.`);
                }).catch(error => {
                    console.error("Error updating document: ", error);
                });
            }
        });
    },

    fileOnFinalize: async function(opt = {}) {
        let resp = { status: "error", error: false };
        if (opt.datasetID) {
            await this.update(opt.datasetID, { uploadStatus: "reintent", uploadStatusMsg: "", uploadRef: opt.filename });
            resp.status = "success";
        }
        else { resp.error = "datasetID is required"; }
        return resp;
    },

    createZip: async function(dataset, images, opt) {
        let imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff', 'webp'];

        let mimeToExt = {
            'image/jpeg':   '.jpeg',
            'image/png':    '.png',
            'image/gif':    '.gif',
            'image/webp':   '.webp',
        };

        if (images.length === 1 && opt.rawImg) {
            let img = images[0];

            let hasImageExtension = imageExtensions.some(ext => img.name.toLowerCase().endsWith('.' + ext));
            let filename = hasImageExtension ? img.name : img.name + '.png';

            let url = URL.createObjectURL(img.file);
            let link = document.createElement('a');

            link.href = url;
            link.download = filename;
            link.click();

            URL.revokeObjectURL(url);

        } else {
            let filenames 	= {};
            let folders 	= [];				
            let zip			= new JSZip();
            let nowdate		= new Date();
            let zipFilename	= dataset.id + "_(" + images.length + ")_" + nowdate.getTime() + ".zip";

            let promises = images.map(async (img) => {
                let tagID   = img.tag.substring(img.tag.lastIndexOf('/') + 1);

                /* if (tagID === '0') {
                    if (dataset.type === 'MULTICLASS') {
                        addFile = false;
                    } else if (dataset.type === 'imageObjectDetection') { 
                        addFile = img.data && img.data.length;
                    }
                } */

                folders[tagID] = zip.folder(tagID);

                let hasImageExtension = imageExtensions.some(ext => img.name.toLowerCase().endsWith('.' + ext));
                let filename = hasImageExtension ? img.name : img.name + '.png';

                filenames[img.name] = (filenames[img.name] || 0) + 1;
                if (filenames[img.name] > 1) {
                    let dotIndex = filename.lastIndexOf('.');
                    filename = `${filename.substring(0, dotIndex)}_${filenames[img.name] - 1}${filename.substring(dotIndex)}`;
                }

                if (img.data && Object.keys(img.data).length && img.type !== 'box') {
                    folders[tagID]['masks'] = zip.folder(tagID + '/masks');

                    if (img.type === 'masks') {
                        await Promise.all(img.data.objects.map(async (mask) => {
                            mask.name       = mask.name === '0' ? 'Anomaly' : mask.name;
                            let mimeString  = mask.src.split(',')[0].split(':')[1].split(';')[0];
                            let maskName    = filename.substring(0, filename.lastIndexOf('.')) + '-' + mask.name + mimeToExt[mimeString];
                            let maskFile    = await fetch(mask.src).then(res => res.blob());
                            folders[tagID]['masks'].file(maskName, maskFile, { base64: true });
                        }));
                    }
                    
                    else if (img.type === 'mask') {
                        let maskJSON = false;

                        try { maskJSON = JSON.parse(img.data.imageJson); }
                        catch (error) { maskJSON = JSON.parse(lzstring.decompressFromUint8Array(img.data.imageJson.toUint8Array())); }

                        if (maskJSON) {
                            let canvas = new fabric.Canvas('canvas');
                            canvas.setWidth(img.data.width);
                            canvas.setHeight(img.data.height);
                            canvas.loadFromJSON(maskJSON);
                            let maskFile = canvas.toDataURL();
                            maskFile = await fetch(maskFile).then(res => res.blob());
                            folders[tagID]['masks'].file(filename, maskFile, { base64: true });
                        }
                    }
                }

                const createdDate = moment.utc(img.createdDate, "DD/MM/YYYY HH:mm:ss").toDate();

                folders[tagID].file(filename, img.file, { base64: true, date: createdDate });
                return folders;
            });

            await Promise.all(promises);

            const manifest = await aws.generateManifest(dataset, images);
            zip.file("test.manifest", 	manifest['test']);
            zip.file("train.manifest", 	manifest['train']);

            await zip.generateAsync({ type: "blob", compression: "STORE" }).then(async function (blob) { saveAs(blob, zipFilename) });
        }
    },

    downloadZip: async function(datasetID) {
        let resp = { status: "error", error: false };

        let media = await this.getImages({ datasetID: datasetID });
        let tags = await this.getTags(datasetID);
        let zip = { name: "zip", count: 0, files: [], folders: [] };
        var nowDate = new Date();

        if (media.count) {
            for (var i = 0; i < Object.keys(media.media).length; i++) {
                if (media.media[i].uri) {
                    let storageUrl = await image.getStorageUrl(media.media[i].uri);
                    if (storageUrl.url) {
                        zip.files.push({
                            name: media.media[i].name.substr(media.media[i].uri.lastIndexOf("/") + 1).replace(/\s+/g, '_'),
                            tag: media.media[i].tagName && media.media[i].tagName[3] ? media.media[i].tagName[3] : false,
                            blob: fetch(storageUrl.url).then(response => response.blob()),
                        })
                        zip.count++;
                    }
                }
            }

            var z = new JSZip();
            if (media.type && (media.type === 'MULTICLASS' || media.type === 'MULTILABEL')) {
                //create tags forlder
                if (Object.keys(tags).length) {
                    for (const k of Object.keys(tags)) {
                        zip.folders[tags[k].id] = z.folder(tags[k].id);
                    }
                }
                //insert folder images
                for (let i = 0; i < zip.files.length; i++) {
                    if (zip.files[i].tag) zip.folders[zip.files[i].tag].file(zip.files[i].name, zip.files[i].blob, { base64: true });
                }
            } else {
                zip.folders[datasetID.replace(/\s+/g, '_')] = z.folder(datasetID.replace(/\s+/g, '_'));
                //insert images
                for (let i = 0; i < zip.files.length; i++) {
                    zip.folders[datasetID.replace(/\s+/g, '_')].file(zip.files[i].name, zip.files[i].blob, { base64: true })
                }
            }

            zip.name = datasetID.replace(/\s+/g, '_') + "_media_" + nowDate.getTime() + "_" + zip.count + ".zip";
            await z.generateAsync({ type: "blob" }).then(async function(blob) { saveAs(blob, zip.name); });
            await event.saveEvent('dataset.download', { dataset: datasetID, filename: zip.name, format: "zip", size: zip.files.length }, false); //uid: useStore().state.main.User.uid,

            resp.status = "success";
            resp.name = zip.name;
            resp.images = zip.count;
            resp.message = "The download will start automatically";
        } else { resp.error = "Dataset has no images"; }

        return resp;
    },

    uploadZip: async function(datasetID, usapi) {
        console.log("Uploading")
        let resp                = { status: "success", error: false };
        const functionsUsApi    = getFunctions(_firebase.firebase, usapi);
        console.log("Functions loaded: ", _firebase.functions);
        const action            = httpsCallable(_firebase.functions, 'api/dataset/' + datasetID + '/importZip');
        await action({}).then(() => {}).catch(async (error) => { resp.status = "error"; resp.error = error; });
        return resp;
    },

    trackZipUpload: async function(datasetID, onProgressUpdate) {
        const datasetRef = doc(_firebase.firestore, 'dataset', datasetID);
        const tracker = onSnapshot(datasetRef, { includeMetadataChanges: true }, (doc) => {
            if (doc.exists) {
                const uploadStatus      = doc.data().uploadStatus;
                const uploadStatusMsg   = doc.data().uploadStatusMsg;
                onProgressUpdate(uploadStatus, uploadStatusMsg);
            }
        });
        return tracker;
    },

    setImagesUpdatedDate: async function(datasetID,) {
        const db = _firebase.firestore;
        let resp = { status: "error", error: false, processingCount: 0, updateCount: 0 };

        if (datasetID) {
            let media = await this.getImages({ datasetID: datasetID });
            if (media.count) {
                resp.processingCount = media.count;
                for (var i = 0; i < Object.keys(media.media).length; i++) {
                    if (media.media[i] && !media.media[i].updatedAt) {
                        resp.updateCount++;
                        let docRef = doc(db, 'image', media.media[i].id);
                        await setDoc(docRef, { updatedAt: Timestamp.fromMillis(media.media[i].date) }, { merge: true });
                    }
                }
                resp.status = "success";
            } else { resp.error = "dataset not have images"; }
        } else { resp.error = "dataset Id is required"; }

        return resp;
    },

    resetDatasets: async function() {
        let resp               = { status: "error", error: false }
        resp.status            = "success";
        await other.httpsCallable('api/dataset/resetDatasets');
        return resp;
    },
}

export default dataset;